import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import {useDispatch, useSelector} from 'react-redux';
import OuterBox from '../../../components/layout/OuterBox';
import InnerBox from '../../../components/layout/InnerBox';
import LoginButton from './LoginButton';
import PublicAppData from '../../../types/brainCloud/PublicAppData';
import AppConfigData from '../../../types/brainCloud/AppConfigData';
import {STARTING_AUTH, HANDOFF_TOKEN, NO_PERMISSIONS_ERROR} from '../../../store/actions/auth';
import moment from 'moment';

// var zlib = require('zlib');

const Base64 = {
	_keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
	encode: function (e) {
		var t = "";
		var n, r, i, s, o, u, a;
		var f = 0;
		e = Base64._utf8_encode(e);

		while (f < e.length) {
			n = e.charCodeAt(f++);
			r = e.charCodeAt(f++);
			i = e.charCodeAt(f++);
			s = n >> 2;
			o = (n & 3) << 4 | r >> 4;
			u = (r & 15) << 2 | i >> 6;
			a = i & 63;
			
			if (isNaN(r)) {
				u = a = 64;
			} else if (isNaN(i)) {
				a = 64;
			}
			
			t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
		}

		return t
	},
	_utf8_encode: function (e) {
		e = e.replace(/\r\n/g, "\n");
		var t = "";

		for (var n = 0; n < e.length; n++) {
			var r = e.charCodeAt(n);

			if (r < 128) {
				t += String.fromCharCode(r);
			} else if (r > 127 && r < 2048) {
				t += String.fromCharCode(r >> 6 | 192);
				t += String.fromCharCode(r & 63 | 128);
			} else {
				t += String.fromCharCode(r >> 12 | 224);
				t += String.fromCharCode(r >> 6 & 63 | 128);
				t += String.fromCharCode(r & 63 | 128);
			}
		}
		
		return t
	}
};

function encodeSamlRedirect(input): Promise<string> {
	return new Promise((resolve, reject) => {
		if (input == null || input == "") {
			reject('Cannot encode null string');
		}
	
		resolve(Base64.encode(input));

		// return zlib.deflateRaw(input, function (err, deflated) {
		// 	if (!err) {
		// 		var b64 = deflated.toString('base64');
		// 		resolve(b64);
		// 	} else {
		// 		reject(err);
		// 	}
		// });
	});
}

// function decodeSamlRedirect(encoded): Promise<string> {
// 	return new Promise((resolve, reject) => {
// 		if (encoded == null || encoded == "") {
// 			reject('Cannot decode null string');
// 		}
	
// 		var deflated = new Buffer(decodeURIComponent(encoded), 'base64');
	
// 		return zlib.inflateRaw(deflated, function (err, inflated) {
// 			if (!err) {
// 				resolve(inflated.toString('ascii'));
// 			} else {
// 				reject(err);
// 			}
// 		});
// 	});
// }

function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return 'TS_' + uuid;
}

function authnRequestTemplate(issuer: string) {
	const id = create_UUID();
	const issueInstant = moment().utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";

	return `
		<samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" ID="${id}" Version="2.0" IssueInstant="${issueInstant}">
			<saml:Issuer>${issuer}</saml:Issuer>
		</samlp:AuthnRequest>
	`;
}

async function generateAuthNRequest(issuer: string): Promise<string> {
	try {
		const xml = authnRequestTemplate(issuer);

		const encoded = await encodeSamlRedirect(xml);

		return Promise.resolve(encoded);
	} catch(err) {
		return Promise.reject(err);
	}
}

const Saml = (props) => {
	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.publicAppData
	);

	const isAuthing = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.appState?.isAuthing
	);
	const dispatch = useDispatch();

	const [authnRequest, setAuthnRequest] = useState('');

	const myForm = useRef();

	const issuer = publicAppData.saml.issuer;
	const ssoUrl = publicAppData.saml.ssoUrl;
	const relayState = { appId: publicAppData.projectAppId, pageName: "passport" };

	useEffect(() => {
		let isMounted = true
		generateAuthNRequest(issuer).then(authnRequest => {
			if (isMounted) {
				setAuthnRequest(authnRequest);
			}
		})
		return () => { isMounted = false; }
	}, []);

	const handleLogin = async () => {
		localStorage.removeItem(NO_PERMISSIONS_ERROR);
		localStorage.removeItem(HANDOFF_TOKEN);
		localStorage.removeItem('securityToken');
		localStorage.removeItem('expireSeconds');
		localStorage.removeItem('currentDate');
		localStorage.removeItem('expireDate');
		localStorage.removeItem('token');
		
		dispatch({
			type: STARTING_AUTH,
			payload: {},
		});

		if (myForm != null && myForm.current != null) {
			const form = myForm.current as any;

			if (form != null) {
				form[0].value = await generateAuthNRequest(issuer);
				
				form.submit();
			}
		}
	};

	return (
		<OuterBox>
			<InnerBox>
				<Avatar>
					<LockIcon />
				</Avatar>

				<form ref={myForm}  method="post" action={ssoUrl}>
					<input type="hidden" name="SAMLRequest" value={authnRequest} />
					<input type="hidden" name="RelayState" value={JSON.stringify(relayState)} />
				</form>

				<LoginButton isSubmitting={isAuthing} onClick={handleLogin}>
					LOG IN With SAML2.0
				</LoginButton>
			</InnerBox>
		</OuterBox>
	);
};

export default Saml;
