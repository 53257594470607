import {
	SET_BRANDING_CSS_DATA,
} from 'store/actions/branding';
import { BrandingCSSData } from 'types/brainCloud/BrandingData'

const initialState: BrandingCSSData = {
	'page-background-gradient-start': '#727272',
	'page-background-gradient-end': '#4C4C4C',
	'page-primary-font-color': '#FFFFFF',
	'page-secondary-font-color': '#FFC100',

	'error-background-gradient-start': '#FF0000',
	'error-background-gradient-end': '#FF0000',
	'error-font-color': '#FFFFFF',

	'footer-background-gradient-start': '#242424',
	'footer-background-gradient-end': '#242424',
	'footer-font-color': '#4C4C4C',

	'input-control-background-color': '#242424',
	'input-control-font-color': '#FFFFFF',
	'input-control-active-border-color': '#FFC100',

	'login-button-background-color': '#FFC100',
	'login-button-font-color': '#000000',
	'login-button-hover-background-color': '#B28700',

	'generate-pin-button-background-color': '#FFC100',
	'generate-pin-button-font-color': '#000000',
	'generate-pin-button-hover-background-color': '#B28700',

	'refresh-pin-button-background-color': '#FFC100',
	'refresh-pin-button-font-color': '#000000',
	'refresh-pin-button-hover-background-color': '#B28700',

	'logout-button-background-color': 'transparent',
	'logout-button-font-color': '#FFC100',
	'logout-button-hover-background-color': 'transparent',

	'tos-terms-of-service-button-background-color': '#FFC100',
	'tos-terms-of-service-button-font-color': '#000000',
	'tos-terms-of-service-button-hover-background-color': '#B28700',

	'tos-privacy-policy-button-background-color': '#FFC100',
	'tos-privacy-policy-button-font-color': '#000000',
	'tos-privacy-policy-button-hover-background-color': '#B28700',

	'tos-continue-button-background-color': '#FFC100',
	'tos-continue-button-font-color': '#000000',
	'tos-continue-button-hover-background-color': '#B28700',

	'navigation-background-color': '#242424',
	'navigation-font-color': '#FFC100',
	'navigation-icon-color': '#FFC100',

	'pin-letter-font-color': '#FFFFFF',
	'pin-number-font-color': '#FFC100',
	'pin-expiration-font-color': '#83894A',

	'footer-logo-color': '#4C4C4C',

	'separator-color': '#000000'
};

const BrandingReducer = (state = initialState, action: any) => {
	let newState = Object.assign({}, state);

	const type = action.type;
	const payload = action.payload;

	if (type === SET_BRANDING_CSS_DATA) {
        if (payload) {
            newState = Object.assign(initialState, payload);
        } else {
            newState = Object.assign({}, initialState);
        }
	}

	return newState;
};

export default BrandingReducer;
