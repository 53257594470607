import {createMuiTheme} from '@material-ui/core/styles';
import './index.css';

const HelveticaNow = 'Helvetica Now';

export const passportTheme = createMuiTheme({
	palette: {
		// primary: {main: 'var(--page-primary-font-color)'},
		// secondary: {main: 'var(--page-secondary-font-color)'},

		// text: {
		// 	primary: 'rgba(255, 255, 255, 0.87)',
		// 	secondary: 'rgba(255, 255, 255, 0.54)',
		// 	disabled: 'rgba(255, 255, 255, 0.38)',
		// 	hint: 'rgba(255, 255, 255, 0.38)'
		// }
	},

	typography: {
		fontFamily: HelveticaNow
	},
	
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: 'transparent',
				boxShadow: 'none',
				fontSize: '18px'
			}
		},

		MuiAvatar:  {
			colorDefault: {
				backgroundColor: 'transparent',
				color: 'var(--login-button-background-color)'
			},
			root: {
				margin: 'auto',
				padding: '10px'
			}
		},

		MuiIcon: {
			root: {
				color: 'var(--page-primary-font-color)'
			}
		},

		MuiAppBar: {
			colorPrimary: {
				backgroundColor: 'transparent'
			},
			root: {
				boxShadow: 'none',
				width: '100%',
				flexGrow: 1,
				position: 'static'
			}
		},

		MuiPopover: {
			paper: {
				backgroundColor: 'var(--navigation-background-color)',
				'& .MuiMenuItem-root': {
					backgroundColor: 'var(--navigation-background-color)'
				}
			},
		},

		MuiMenuItem: {
			root: {
				height: '50px',
				backgroundColor: 'var(--navigation-background-color)',
				color: 'var(--navigation-font-color)',
				fontSize: '18px'
			},
		},

		MuiIconButton: {
			root: {
				fontWeight: 700,
				color: 'var(--navigation-icon-color)'
			}
		},

		MuiOutlinedInput: {
			root: {
				borderRadius: 4,
				'&.Mui-focused *': {
					borderColor: 'var(--input-control-active-border-color) !important',
				}
			}
		},

		MuiInputBase: {
			root: {
				backgroundColor: 'var(--input-control-background-color) !important',
				color: 'var(--input-control-font-color) !important'
			},
			input: {
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color'
				}
			}
		},

		MuiTextField: {
			root: {
				marginLeft: 'auto',
				marginRight: 'auto',
				display: 'block',
				width: 300,
				marginTop: 10,
				marginBottom: 10,
			}
		},

		MuiButton: {
			root: {
				marginLeft: 'auto',
				marginRight: 'auto',
				display: 'block',
				width: 300,
				marginTop: 10,
				marginBottom: 10,
				paddingTop: 10,
				paddingBottom: 10,
				borderRadius: 50,
				textTransform: 'none',
				'&#login-button:not(:disabled)': {
					backgroundColor: 'var(--login-button-background-color)',
					color: 'var(--login-button-font-color)',
					'&:hover': {
						backgroundColor: 'var(--login-button-hover-background-color)',
					}
				},
				'&#logout-button:not(:disabled)': {
					backgroundColor: 'var(--logout-button-background-color)',
					color: 'var(--logout-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--logout-button-hover-background-color)',
					}
				},
				'&#generate-pin-button:not(:disabled)': {
					backgroundColor: 'var(--generate-pin-button-background-color)',
					color: 'var(--generate-pin-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--generate-pin-button-hover-background-color)',
					}
				},
				'&#refresh-pin-button:not(:disabled)': {
					backgroundColor: 'var(--refresh-pin-button-background-color)',
					color: 'var(--refresh-pin-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--refresh-pin-button-hover-background-color)',
					}
				},
				'&#tos-terms-of-service-button:not(:disabled)': {
					backgroundColor: 'var(--tos-terms-of-service-button-background-color)',
					color: 'var(--tos-terms-of-service-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--tos-terms-of-service-button-hover-background-color)',
					}
				},
				'&#tos-privacy-policy-button:not(:disabled)': {
					backgroundColor: 'var(--tos-privacy-policy-button-background-color)',
					color: 'var(--tos-privacy-policy-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--tos-privacy-policy-button-hover-background-color)',
					}
				},
				'&#tos-continue-button:not(:disabled)': {
					backgroundColor: 'var(--tos-continue-button-background-color)',
					color: 'var(--tos-continue-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--tos-continue-button-hover-background-color)',
					}
				},
				'&#survey-button:not(:disabled)': { // TODO Update these colors.
					backgroundColor: 'var(--tos-continue-button-background-color)',
					color: 'var(--tos-continue-button-font-color)',
					'&:hover' : {
						backgroundColor: 'var(--tos-continue-button-hover-background-color)',
					}
				}
			},
			containedSecondary: {
				borderRadius: 4,
				width: 280,
				'& .MuiButton-label': {
					fontSize: '14px',
					lineHeight: 0
				}
			}
		},

		MuiTypography: {
			h1: {
				color: 'var(--page-primary-font-color)',
				fontWeight: 400,
				fontStyle: 'normal',
				fontSize: '28px',
				textAlign: 'center',
				padding: '20px',
				width: '360px',
				maxWidth: '90%',
				margin: 'auto'
			},
			h2: {
				color: 'var(--page-secondary-font-color)',
				fontStyle: 'normal',
				fontSize: '18px',
				textAlign: 'center',
				width: '360px',
				maxWidth: '90%',
				margin: 'auto',
				paddingTop: '10px',
				paddingBottom: '20px'
			},
			h3: {
				color: 'var(--page-secondary-font-color)',
				fontSize: 28,
				textAlign: 'left',
				width: '360px',
				maxWidth: '90%',
				align: 'left'
			},
			h4: {
				color: 'var(--page-primary-font-color)',
				fontSize: '72px',
				textAlign: 'left',
				maxWidth: '90%'
			},
			body1: {
				color: 'var(--page-primary-font-color)',
				fontSize: '18px',
				overflowWrap: 'break-word',
				width: '360px',
				maxWidth: '80%',
				margin: 'auto',
				display: 'block',
				lineHeight: '20px',
				textAlign: 'center'
			},
			body2: {
				color: 'var(--page-primary-font-color)',
				fontSize: '18px',
				overflowWrap: 'break-word',
				width: '360px',
				maxWidth: '80%',
				margin: 'auto',
				display: 'block',
				lineHeight: '50px'
			}
		},

		MuiCardMedia: {
			root: {
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '70px',
				marginBottom: '10px',
				height: '150px',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'fit',
				display: 'block'
			}
		},

		MuiFormGroup: {
			root: {
				'& .MuiTypography-root': {
					width: '100%',
					marginLeft: 10,
					marginRight: 10,
					textAlign: 'left'
				},
				'& #survey-answer:not(:disabled)': {
					color: 'var(--tos-continue-button-background-color)',
					'& .MuiRadio-root.Mui-checked': {
						'& .MuiSvgIcon-root:first-of-type': {
							color: 'var(--tos-continue-button-background-color)'
						},
						'& .MuiSvgIcon-root:last-of-type': {
							color: 'var(--tos-continue-button-hover-background-color)',
						}
					}
				}

			}
		},
		MuiLinearProgress: {
			root: {
				height: '16px',
				borderRadius: '8px',
				minWidth: '200px',
				marginLeft: '20px',
				marginRight: '20px'
			},
			colorPrimary: {
				backgroundColor: 'var(--tos-continue-button-font-color)',
				border: '1px solid var(--tos-continue-button-background-color)'
			},
			barColorPrimary: {
				backgroundColor: 'var(--tos-continue-button-background-color)'
			},
			bar: {
				borderRadius: '8px'
			}
		}
	}
});

export default passportTheme;
