import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import {UserManager} from 'oidc-client';
import {useDispatch, useSelector} from 'react-redux';
import OuterBox from '../../../components/layout/OuterBox';
import InnerBox from '../../../components/layout/InnerBox';
import LoginButton from './LoginButton';
import PublicAppData from '../../../types/brainCloud/PublicAppData';
import AppConfigData from '../../../types/brainCloud/AppConfigData';
import {STARTING_AUTH, HANDOFF_TOKEN, NO_PERMISSIONS_ERROR} from '../../../store/actions/auth';

const Okta = (props) => {
	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.publicAppData
	);

	const isAuthing = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.appState?.isAuthing
	);
	const dispatch = useDispatch();

	var userManager = null;

	if (publicAppData?.okta?.scopes) {
		var cleanedScopes = publicAppData.okta.scopes
			? publicAppData.okta.scopes.replace(/','/g, ' ')
			: 'openid email profile';

		userManager = new UserManager({
			authority: publicAppData.okta.issuer,
			client_id: publicAppData.okta.clientId,
			redirect_uri: `${window.location.origin}/implicit/callback`,
			response_type: 'token id_token',
			scope: cleanedScopes,
		});
	}

	const handleLogin = () => {
		localStorage.removeItem(NO_PERMISSIONS_ERROR);
		localStorage.removeItem(HANDOFF_TOKEN);
		localStorage.removeItem('securityToken');
		localStorage.removeItem('expireSeconds');
		localStorage.removeItem('currentDate');
		localStorage.removeItem('expireDate');
		localStorage.removeItem('token');
		
		dispatch({
			type: STARTING_AUTH,
			payload: {},
		});

		if (userManager) userManager.signinRedirect();
	};

	return (
		<OuterBox>
			<InnerBox>
				<Avatar>
					<LockIcon />
				</Avatar>

				<LoginButton isSubmitting={isAuthing} onClick={handleLogin}>
					LOG IN With Okta
				</LoginButton>
			</InnerBox>
		</OuterBox>
	);
};

export default Okta;
