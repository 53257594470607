import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { IconButton, Typography } from '@material-ui/core';

import DismissIcon from '@material-ui/icons/Close';

import {DISMISS_SYSTEM_MESSAGE} from '../../store/actions/auth';

import AppConfigData from '../../types/brainCloud/AppConfigData';
import { BrandingCSSData } from 'types/brainCloud/BrandingData';

function SystemMessage({ includeTopMargin }) {
    const dispatch = useDispatch();
    
    const systemMessageDismissed = useSelector((state: {appConfigData: AppConfigData}) => {
        return state.appConfigData?.systemMessageDismissed;
    });

    const systemMessage = useSelector((state: {appConfigData: AppConfigData}) => {
        return state.appConfigData?.publicAppData?.systemMessage;
    });
    
    const brandingData = useSelector((state: {brandingData: BrandingCSSData}) => {
        return state.brandingData;
    });

    const dismissSystemMessage = () => {
        if (!systemMessageDismissed) {
            dispatch({type: DISMISS_SYSTEM_MESSAGE});
        }
    };

    if (systemMessage && systemMessage.enabled && !systemMessageDismissed) {
        const { message, type } = systemMessage;

        const appliedSystemMessageStyle = {
            minHeight: '32px',
            backgroundColor: 'transparent',
            color: 'inherit',
            borderColor: 'inherit',
            borderStyle: 'solid',
            borderTopWidth: '4px',
            borderBottomWidth: '4px',
            borderLeftWidth: '1px',
            borderRightWidth: '1px',
        };

        const appliedDismissButtonStyle = {
            color: '#000000'
        };

        switch (type) {
            case 'general':
                appliedSystemMessageStyle.backgroundColor = brandingData['system-message-general-background-color'] ? brandingData['system-message-general-background-color'] : '#89C32D';
                appliedSystemMessageStyle.color = brandingData['system-message-general-color'] ? brandingData['system-message-general-color'] : '#000000';
                appliedSystemMessageStyle.borderColor = brandingData['system-message-general-border-color'] ? brandingData['system-message-general-border-color'] : '#89C32D';

                appliedDismissButtonStyle.color = brandingData['system-message-general-color'] ? brandingData['system-message-general-color'] : '#000000';

                break;
            case 'warning':
                appliedSystemMessageStyle.backgroundColor = brandingData['system-message-warning-background-color'] ? brandingData['system-message-warning-background-color'] : '#F0A033';
                appliedSystemMessageStyle.color = brandingData['system-message-warning-color'] ? brandingData['system-message-warning-color'] : '#000000';
                appliedSystemMessageStyle.borderColor = brandingData['system-message-warning-border-color'] ? brandingData['system-message-warning-border-color'] : '#F0A033';

                appliedDismissButtonStyle.color = brandingData['system-message-general-color'] ? brandingData['system-message-general-color'] : '#000000';

                break;
            case 'critical':
                appliedSystemMessageStyle.backgroundColor = brandingData['system-message-critical-background-color'] ? brandingData['system-message-critical-background-color'] : '#F03333';
                appliedSystemMessageStyle.color = brandingData['system-message-critical-color'] ? brandingData['system-message-critical-color'] : '#000000';
                appliedSystemMessageStyle.borderColor = brandingData['system-message-critical-border-color'] ? brandingData['system-message-critical-border-color'] : '#F03333';

                appliedDismissButtonStyle.color = brandingData['system-message-general-color'] ? brandingData['system-message-general-color'] : '#000000';

                break;
            default:
                // Do nothing
        }

        return (
            <div style={{ flex: '0 0 auto', width: '100%', marginTop: includeTopMargin ? '60px' : 'unset', display: 'flex', alignItems: 'center'}}>
                <div
                    style={Object.assign({
                        flex: '1 1 auto',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '80px',
                        paddingRight: '24px',
                        boxShadow: '0px 3px 3px #0000004D'
                    }, appliedSystemMessageStyle)}
                >
                    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></div>
        
                    <IconButton aria-label='show more' aria-haspopup='true' onClick={dismissSystemMessage}>
                        <DismissIcon style={Object.assign({ fontSize: '32px', margin: 'auto' }, appliedDismissButtonStyle)} />
                    </IconButton>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default SystemMessage;